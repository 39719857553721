import { useState, useEffect } from 'react'
import { ethers } from "ethers"
import { Row, Col, Card, Button } from 'react-bootstrap'
import QRCode from 'react-qr-code';
import { getLatestTranHash, getNFTMetaData } from './util';
import { Link } from 'react-router-dom';

const Home = ({ marketplace, nft }) => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const loadMarketplaceItems = async () => {

    const transactions = await getLatestTranHash(true);

    for( let ii = 0; ii<transactions.length; ii++ ){
      console.log( transactions[ii] );
      const nft_meta = await getNFTMetaData( transactions[ii].rawContract.address, transactions[ii].tokenId );

        console.log( nft_meta );
        if( nft_meta && nft_meta.rawMetadata){
            let itemD = {
                totalPrice: nft_meta.rawMetadata.price,
                name: nft_meta.rawMetadata.name,
                description: nft_meta.rawMetadata.description,
                image: nft_meta.rawMetadata.image,
                provenance: nft_meta.rawMetadata.provenance,
                agreement: nft_meta.rawMetadata.agreement,
                hash: transactions[ii].hash,
                // transaction_hash: params.id,
                contractAddress: transactions[ii].rawContract.address,
                tokenId: nft_meta.tokenId
            }
            items.push(itemD);
        }
    }

    setLoading(false)
    setItems(items)
  }

  const buyMarketItem = async (item) => {
    await (await marketplace.purchaseItem(item.itemId, { value: item.totalPrice })).wait()
    loadMarketplaceItems()
  }

  const copyToClipboard = async(evt, url) => {
    // const { target } = evt;
    console.log( window.location );
    console.log( url );
    if( url.indexOf('http') == -1 ){
      navigator.clipboard.writeText(window.location.origin + url);
    }else {
      navigator.clipboard.writeText(url);
    }
    
  }

  useEffect(() => {
    loadMarketplaceItems()
  }, [])
  if (loading) return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Loading...</h2>
    </main>
  )
  return (
    <div className="flex justify-center">
      {items.length > 0 ?
        <div className="px-5 container">
          {/* <Row xs={1} md={2} lg={4} className="g-4 py-5">
            {items.map((item, idx) => (
              <Col key={idx} className="overflow-hidden">
                <Card>
                  <QRCode value={item.image} />
                  <Card.Body color="secondary">
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>
                      {item.description}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <div className='d-grid'>
                      <Button onClick={() => buyMarketItem(item)} variant="primary" size="lg">
                        Buy for {item.totalPrice} ETH
                      </Button>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row> */}
          <Row xs={1} md={2} className="g-4 py-3">
              {items.map((item, idx) => (
                <Col key={idx} className="overflow-hidden">
                  <Card>
                    <Row xs={2}>
                      <Col>
                        <Card.Img variant="top" src={item.image} style={{ width: '100%' }} />
                      </Col>
                      <Col>
                        <h6>IPFS: </h6>
                        <div>
                          <span>Image:</span>
                          <QRCode onClick={(evt) => { window.open(item.image, '_blank') }} value={item.image} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer' }} /> 
                        </div>
                        <div style={{ marginTop: 5 }}>
                          <span>Provenance:</span>
                          <QRCode onClick={(evt) => { window.open(item.provenance, '_blank') }} value={item.provenance} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer'}} />    
                        </div>
                        <div style={{ marginTop: 5 }}>
                          <span>Agreement:</span>
                          <QRCode onClick={(evt) => { window.open(item.agreement, '_blank') }} value={item.agreement} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer'}} />    
                        </div>

                        <h6>Etherscan:</h6>
                        <div style={{ marginTop: 5 }}>
                          <span>Etherscan:</span>
                          <QRCode onClick={(evt) => { window.open(`https://sepolia.etherscan.io/tx/${item.hash}`, '_blank') }} value={`https://sepolia.etherscan.io/tx/${item.hash}`} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer'}} />    
                        </div>
                      </Col>
                    </Row>
                    <Card.Footer>{item.totalPrice} ETH</Card.Footer>
                    <div className="button-panel">
                      <Link to={`/item-detail/${item.hash}`}>Detail</Link>
                      {/* <Button 
                        onClick={(evt) => copyToClipboard(evt, `/item-detail/${item.indx}`)}
                        style={{ 
                          background: '#FFF', 
                          border: '1px solid #000', 
                          borderRadius: 0, 
                          color: '#000',
                          marginLeft: 5,
                         }}
                        >Copy Detail Url</Button> */}
                      <Button 
                        onClick={(evt) => copyToClipboard(evt, `https://sepolia.etherscan.io/tx/${item.hash}`)}
                        style={{ 
                          background: '#FFF', 
                          border: '1px solid #000', 
                          borderRadius: 0, 
                          color: '#000',
                          marginLeft: 5,
                         }}
                        >Copy Etherscan Url</Button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
        </div>
        : (
          <main style={{ padding: "1rem 0" }}>
            <h2>No listed assets</h2>
          </main>
        )}
    </div>
  );
}
export default Home