import { useState, useEffect } from 'react'
import { ethers } from "ethers"
import { Row, Col, Card, Button } from 'react-bootstrap'
import QRCode from "react-qr-code"
import { Link } from 'react-router-dom'

function renderSoldItems(items) {
  return (
    <>
      <h2>Sold</h2>
      <Row xs={1} md={2} lg={4} className="g-4 py-3">
        {items.map((item, idx) => (
          <Col key={idx} className="overflow-hidden">
            <Card>
              <Card.Img variant="top" src={item.image} />
              <Card.Footer>
                For {ethers.utils.formatEther(item.totalPrice)} ETH - Recieved {ethers.utils.formatEther(item.price)} ETH
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default function MyListedItems({ marketplace, nft, account, transactions }) {
  const [loading, setLoading] = useState(true)
  const [listedItems, setListedItems] = useState([])
  const [soldItems, setSoldItems] = useState([])

  console.log( transactions );

  const loadListedItems = async () => {
    // console.log( marketplace );
    // Load all sold items that the user listed
    const itemCount = await marketplace.itemCount()
    // console.log( itemCount );
    let listedItems = []
    let soldItems = []
    for (let indx = 1; indx <= itemCount; indx++) {
      const i = await marketplace.items(indx)
      console.log( i );
      if (i.seller.toLowerCase() === account) {
        // get uri url from nft contract
        const uri = await nft.tokenURI(i.tokenId)
        console.log( uri );

        const matched_nft = transactions.filter(transaction => transaction.to == i.nft)
        console.log( matched_nft );
        
        if( matched_nft.length ){
          // use uri to fetch the nft metadata stored on ipfs 
          const response = await fetch(uri)
          const metadata = await response.json()
          // console.log( metadata );
          // get total price of item (item price + fee)
          const totalPrice = await marketplace.getTotalPrice(i.itemId)
          // define listed item object
          let item = {
            indx: indx,
            tokenId: i.tokenId,
            totalPrice,
            price: i.price,
            itemId: i.itemId,
            name: metadata.name,
            description: metadata.description,
            image: metadata.image,
            provenance: metadata.provenance,
            agreement: metadata.agreement,
            hash: matched_nft[0].hash,
          }
          listedItems.push(item)
          // Add listed item to sold items array if sold
          if (i.sold) soldItems.push(item)
        }
        
      }
    }
    setLoading(false)
    setListedItems(listedItems)
    setSoldItems(soldItems)
  }

  const attachToNFC = async(url) => {
    console.log( window );
    // if( 'NDEFReader' in window ){
    //   if( NDEFReader ){
    //     try{
    //       //Attach IPFS address to NFC for physical art
    //       const ndef = new NDEFReader();
          
    //     } catch(e){
    //       console.log( e );
    //     }
    //   } else {
    //     console.log(`NDEFReader is not defined`);
    //   }
      
    // } else {
    //   alert(`Your browser doesn't support NFC`)
    // }
  }

  const copyToClipboard = async(evt, url) => {
    // const { target } = evt;
    console.log( window.location );
    console.log( url );
    if( url.indexOf('http') == -1 ){
      navigator.clipboard.writeText(window.location.origin + url);
    }else {
      navigator.clipboard.writeText(url);
    }
    
  }

  useEffect(() => {
    loadListedItems()
  }, [])
  
  if (loading) return (
    <main style={{ padding: "1rem 0" }}>
      <h2>Loading...</h2>
    </main>
  )

  console.log(listedItems);
  return (
    <div className="flex justify-center">
      {listedItems.length > 0 ?
        <div className="px-5 py-3 container">
            <h2>Listed</h2>
            <Row xs={1} md={2} className="g-4 py-3">
              {listedItems.map((item, idx) => (
                <Col key={idx} className="overflow-hidden">
                  <Card>
                    <Row xs={2}>
                      <Col>
                        <Card.Img variant="top" src={item.image} style={{ width: '100%' }} />
                      </Col>
                      <Col>
                        <h6>IPFS: </h6>
                        <div>
                          <span>Image:</span>
                          <QRCode onClick={(evt) => { window.open(item.image, '_blank') }} value={item.image} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer' }} /> 
                        </div>
                        <div style={{ marginTop: 5 }}>
                          <span>Provenance:</span>
                          <QRCode onClick={(evt) => { window.open(item.provenance, '_blank') }} value={item.provenance} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer'}} />    
                        </div>
                        <div style={{ marginTop: 5 }}>
                          <span>Agreement:</span>
                          <QRCode onClick={(evt) => { window.open(item.agreement, '_blank') }} value={item.agreement} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer'}} />    
                        </div>

                        <h6>Etherscan:</h6>
                        <div style={{ marginTop: 5 }}>
                          <span>Etherscan:</span>
                          <QRCode onClick={(evt) => { window.open(`https://sepolia.etherscan.io/tx/${item.hash}`, '_blank') }} value={`https://sepolia.etherscan.io/tx/${item.hash}`} style={{ width: 50, height: 50, marginLeft: 5, cursor: 'pointer'}} />    
                        </div>
                      </Col>
                    </Row>
                    <Card.Footer>{ethers.utils.formatEther(item.totalPrice)} ETH</Card.Footer>
                    <div className="button-panel">
                      {/* <Link to={`/item-detail/${item.indx}`}>Detail</Link> */}
                      {/* <Button 
                        onClick={(evt) => copyToClipboard(evt, `/item-detail/${item.indx}`)}
                        style={{ 
                          background: '#FFF', 
                          border: '1px solid #000', 
                          borderRadius: 0, 
                          color: '#000',
                          marginLeft: 5,
                         }}
                        >Copy Detail Url</Button> */}
                      <Button 
                        onClick={(evt) => copyToClipboard(evt, `https://sepolia.etherscan.io/tx/${item.hash}`)}
                        style={{ 
                          background: '#FFF', 
                          border: '1px solid #000', 
                          borderRadius: 0, 
                          color: '#000',
                          marginLeft: 5,
                         }}
                        >Copy Etherscan Url</Button>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            {soldItems.length > 0 && renderSoldItems(soldItems)}
        </div>
        : (
          <main style={{ padding: "1rem 0" }}>
            <h2>No listed assets</h2>
          </main>
        )}
    </div>
  );
}