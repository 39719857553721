import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useParams } from 'react-router';
import { getLatestTranHash, getNFTMetaData } from './util';

export default function ItemDetail({ marketplace, nft }){
    const [itemDetail, setItemDetail] = useState({})
    const params = useParams();

    const loadItemDetail = async () => {

        if( params && params.id ){
            const transactions = await getLatestTranHash(true);
            console.log( transactions );
    
            let matched_transaction = transactions.filter(tran => tran.hash == params.id)
            console.log( matched_transaction );
            if( matched_transaction.length ){
                const nft_meta = await getNFTMetaData( matched_transaction[0].rawContract.address, matched_transaction[0].tokenId );

                console.log( nft_meta );
                if( nft_meta && nft_meta.rawMetadata){
                    let itemD = {
                        price: nft_meta.rawMetadata.price,
                        name: nft_meta.rawMetadata.name,
                        description: nft_meta.rawMetadata.description,
                        image: nft_meta.rawMetadata.image,
                        provenance: nft_meta.rawMetadata.provenance,
                        agreement: nft_meta.rawMetadata.agreement,
                        transaction_hash: params.id,
                        contractAddress: matched_transaction[0].rawContract.address,
                        tokenId: nft_meta.tokenId
                    }

                    setItemDetail(itemD);
                }
            }
        }
        
    }

    console.log( itemDetail );

    useEffect(() => {
        loadItemDetail()
    }, [])

    return (
        <div className="container">
            <div className='row'>
                <div className='col col-12'>
                    <Card>
                        <a href={itemDetail.image} target='_blank'>
                            <Card.Img variant='top' src={itemDetail.image} style={{ maxWidth: 200 }} />
                        </a>
                    </Card>
                    <div className="button-panel">
                        <a href={itemDetail.provenance} className="button-link" target='_blank'>Provenance</a>
                        <a href={itemDetail.agreement} className="button-link" target='_blank'>Agreement</a>
                    </div>
                    
                </div>
            </div>
            <div className='row'>
                <div className='col col-12 col-md-3'>
                    <span>Name</span>
                </div>
                <div className='col col-12 col-md-9'>
                    <span>{itemDetail.name}</span>
                </div>
            </div>
            <div className='row'>
                <div className='col col-12 col-md-3'>
                    <span>Description</span>
                </div>
                <div className='col col-12 col-md-9'>
                    {itemDetail.description}
                </div>
            </div>
            <div className="row">
                <div className="col col-12">
                    <Button
                        onClick={(evt) => { window.open(`https://sepolia.etherscan.io/nft/${itemDetail.contractAddress}/${itemDetail.tokenId}`) }}
                        style={{ 
                            background: '#FFF', 
                            border: '1px solid #000', 
                            borderRadius: 0, 
                            color: '#000',
                            marginLeft: 5,
                           }}
                    >View on etherscan</Button>
                </div>
            </div>
        </div>
    );
}