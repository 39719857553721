import { Alchemy, Network } from 'alchemy-sdk';

const config = {
    apiKey: '_B466uj5NQNf2qw1ibWkXlbKZ5NROd47',
    network: Network.ETH_SEPOLIA,
};

const alchemy = new Alchemy(config);

export const getLatestTranHash = async ( return_all ) => {

    const toAddress = '0x8D51FBAf09Ce27dc932391169AeaC7171d126006';
    
    const res = await alchemy.core.getAssetTransfers({
      excludeZeroValue: true,
      category: ["erc721"],
      toAddress: toAddress,
      order: "desc"
    });
  
    console.log( res.transfers );
    
    if( return_all ){
        return res.transfers;
    } else {
        let hash = ''
        if( res.transfers.length ){
        hash = res.transfers[0].hash
        }
        return hash;
    }
    
}

export const getNFTMetaData = async (contractAddress, tokenId) => {
    // const contractAddress = '0x48cbf6c78764dc6bf3d18e54fe514f092375f88e';
    let response = await alchemy.nft.getNftMetadata(contractAddress, tokenId);
    return response;
}