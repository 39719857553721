import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Navigation from './Navbar';
import Home from './Home.js'
import Create from './Create.js';
import Verify from './Verify.js';
import ItemDetail from './ItemDetail';
import MyListedItems from './MyListedItems.js'
import MyPurchases from './MyPurchases.js'
import MarketplaceAbi from '../contractsData/Marketplace.json'
import MarketplaceAddress from '../contractsData/Marketplace-address.json'
import NFTAbi from '../contractsData/NFT.json'
import NFTAddress from '../contractsData/NFT-address.json'
import { useState } from 'react'
import { ethers } from "ethers"
import { Spinner } from 'react-bootstrap'
import { app, analytics, db } from './Fb';

import './App.css';

function App() {
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState(null)
  const [nft, setNFT] = useState({})
  const [marketplace, setMarketplace] = useState({})
  const [transactions, setTransactions] = useState([])
  
  // MetaMask Login/Connect
  const web3Handler = async () => {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    console.log( window.ethereum );
    console.log( accounts )

    setAccount(accounts[0])
    // Get provider from Metamask
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    // const api_key = '_B466uj5NQNf2qw1ibWkXlbKZ5NROd47';
    // const url = `https://eth-sepolia.g.alchemy.com/v2/${api_key}`;
    // const provider = new ethers.providers.JsonRpcProvider( url );
    // window.ethereum.enable()
    // Set signer
    const signer = provider.getSigner()

    const etherscanProvider = new ethers.providers.EtherscanProvider('sepolia');
    console.log( etherscanProvider );
    etherscanProvider.getHistory(accounts[0]).then((history) => {
      console.log( history );
      setTransactions(history);
    })
    
    window.ethereum.on('chainChanged', (chainId) => {
      console.log( chainId );
      window.location.reload();
    })

    window.ethereum.on('accountsChanged', async function (accounts) {
      setAccount(accounts[0])
      await web3Handler()
    })
    loadContracts(signer)
  }
  
  const loadContracts = async (signer) => {
    
    // Get deployed copies of contracts
    const marketplace = new ethers.Contract(MarketplaceAddress.address, MarketplaceAbi.abi, signer)
    console.log( marketplace );
    setMarketplace(marketplace)
    const nft = new ethers.Contract(NFTAddress.address, NFTAbi.abi, signer)
    console.log( nft );
    // console.log( nft.mint )
    // console.log( nft.tokenCount() );
    // const token_count = await nft.tokenCount();
    // console.log( token_count );
    // console.log( token_count.toHexString() );
    setNFT(nft)
    setLoading(false)
  }

  return (
    <BrowserRouter>
      <div className="App">
        <>
          <Navigation web3Handler={web3Handler} account={account} />
        </>
        <div>
          {loading ? (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                paddingTop: 10, 
                paddingBottom: 10, 
                background: '#CCC' 
              }}>
              <Spinner animation="border" style={{ display: 'flex', size: 6 }} />
              <p className='mx-3 my-0'>Awaiting Metamask Connection...</p>
            </div>
          ) : (
            <React.Fragment></React.Fragment>  
          )}

          <Routes>
            <Route path="/" element={
              <Home marketplace={marketplace} nft={nft} />
            } />
            <Route path="/mint" element={
              <Create marketplace={marketplace} nft={nft} fbapp={{ app, analytics, db }} />
            } />
            {/* <Route path='/verify' element={
              <Verify />
            } /> */}
            <Route path="/my-listed-items" element={
              <MyListedItems marketplace={marketplace} nft={nft} account={account} transactions={transactions} />
            } />
            <Route path="/item-detail/:id" element={
              <ItemDetail marketplace={marketplace} nft={nft} />
            } />
            <Route path="/my-purchases" element={
              <MyPurchases marketplace={marketplace} nft={nft} account={account} />
            } />
          </Routes>
 
        </div>
      </div>
    </BrowserRouter>

  );
}

export default App;
