import { useState } from 'react'
import { ethers } from "ethers"
import { useNavigate } from 'react-router-dom';
import { Row, Form, Button, Spinner } from 'react-bootstrap'
import { create as ipfsHttpClient } from 'ipfs-http-client'
import { collection, addDoc } from 'firebase/firestore'
// import { Alchemy, Network } from 'alchemy-sdk';
import { getLatestTranHash } from './util'

var Buffer = require('buffer/').Buffer

// Infura credential
const auth =
    'Basic ' + Buffer.from('2JcX76XqvopaDZ2psVp5kh1qwms' + ':' + '62f17ccec4c098057089033ab4e41870').toString('base64');
const client = ipfsHttpClient(
    {
      host: 'ipfs.infura.io',
      port: 5001,
      protocol: 'https',
      headers: {
        authorization: auth
      }
    }
)
const root = `https://art-dao.infura-ipfs.io/ipfs/`;

// Localhost
// const auth =
//     'Basic ' + Buffer.from('2JcX76XqvopaDZ2psVp5kh1qwms' + ':' + '62f17ccec4c098057089033ab4e41870').toString('base64');
// const client = ipfsHttpClient(
//     {
//       host: 'localhost',
//       port: 5001,
//       protocol: 'http',
//       // headers: {
//       //   authorization: auth
//       // }
//     }
// )
// const root = `http://localhost:8080/ipfs/`;

const Create = ({ marketplace, nft, fbapp }) => {
  const navigate = useNavigate();
  // console.log( fbapp );
  const [image, setImage] = useState('')
  const [provenance, setProvenance] = useState('')
  const [agreement, setAgreement] = useState('')
  const [price, setPrice] = useState(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [available, setAvailable] = useState(false)
  const [mintingInProgress, setMintingInProgress] = useState(false)

  const uploadToIPFS = async (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    const file_type = event.target.name;
    if (typeof file !== 'undefined') {
      try {
        const result = await client.add(file)
        console.log(result)
        switch( file_type ){
          case 'image':
            setImage(`${root}${result.path}`);
            break;
          case 'provenance':
            setProvenance(`${root}${result.path}`);
            break;
          case 'agreement':
            setAgreement(`${root}${result.path}`);
            break;
        }
        
      } catch (error){
        console.log("ipfs image upload error: ", error)
      }
    }
  }

  const createNFT = async () => {
    if (!image || !provenance || !agreement || !price || !name || !description) return
    try{
      setMintingInProgress(true);
      const string_payload = JSON.stringify({image, provenance, agreement, price, name, description})
      const result = await client.add(string_payload)
      mintThenList(result, string_payload)
    } catch(error) {
      console.log("ipfs uri upload error: ", error)
    }
  }

  const mintThenList = async (result, payload) => {
    console.log("starting minting");
    console.log(result);
    const uri = `${root}${result.path}`

    console.log(`Debug uri: ${uri}`)
    // mint nft 
    await(await nft.mint(uri)).wait()
    // get tokenId of new nft 
    const id = await nft.tokenCount()
    console.log(`finished mint, token id: ${id}`)
    console.log( typeof id );

    // approve marketplace to spend nft
    await(await nft.setApprovalForAll(marketplace.address, true)).wait()
    // add nft to marketplace
    const listingPrice = ethers.utils.parseEther(price.toString())
    console.log(`Got token id, price: ${listingPrice}`)
    
    await(await marketplace.makeItem(nft.address, id, listingPrice)).wait()
    console.log('finished make item')
    console.log( nft.address )

    if( available && fbapp && fbapp.db ){
      const transaction_hash = await getLatestTranHash();
      try{
        const docRef = await addDoc(collection(fbapp.db, 'nft'), 
          {
            hash: transaction_hash, 
            contractAddress: nft.address,
            tokenId: ethers.utils.formatEther(id),
            payload
          });
        console.log(`Document wrtten with ID: ${docRef.id}`)

        setMintingInProgress(false);
        // window.open(`/item-detail/${transaction_hash}`)
        navigate(`/item-detail/${transaction_hash}`);
      } catch(e){
        console.error(e);
        alert('There is an error while minting. Please try again later.');
      }
    } else {
      setMintingInProgress(false);
      // window.open(`/item-detail/${transaction_hash}`)
      alert('There is an error while minting. Please try again later.');
    }
  }

  return (
    <div className="container-fluid mt-5">
      {
        mintingInProgress ? <div className="row">
            <div className="col col-12">
              <div style={{ display:'flex', justifyContent: 'center' }}>
                <Spinner animation="border" variant="primary" />
                <span>NFC minting in progress.</span>
              </div>
            </div>
          </div> : ''
      }
      
      <div className="row">
        <main role="main" className="col-lg-12 mx-auto" style={{ maxWidth: '1000px' }}>
          <div className="content mx-auto">
            <div className='row'>
              <div className='col-12 col-md-3'>
                <label>Art</label>
              </div>
              <div className='col-12 col-md-9'>
                <Form.Control
                  type="file"
                  required
                  name="image"
                  onChange={uploadToIPFS}
                  />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <label>Provenance</label>
              </div>
              <div className="col-12 col-md-9">
                <Form.Control
                  type="file"
                  required
                  name="provenance"
                  onChange={uploadToIPFS}
                  />
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-3'>
                <label>Agreement</label>
              </div>
              <div className='col-12 col-md-9'>
                <Form.Control
                  type="file"
                  required
                  name="agreement"
                  onChange={uploadToIPFS}
                  />
              </div>
            </div>
            <Row className="g-4">
              
              <Form.Control onChange={(e) => setName(e.target.value)} size="lg" required type="text" placeholder="Name" />
              <Form.Control onChange={(e) => setDescription(e.target.value)} size="lg" required as="textarea" placeholder="Description" />
              <Form.Control onChange={(e) => setPrice(e.target.value)} size="lg" required type="number" placeholder="Price in ETH" />
              <Form.Check onChange={(e) => setAvailable(e.target.checked)} type='checkbox' label='Embed Arthole NFC' />
              <div className="d-grid px-0">
                <Button onClick={createNFT} variant="primary" size="lg">
                  Mint & List NFT!
                </Button>
              </div>
            </Row>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Create